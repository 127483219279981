.project-full {
  width: 100%;
  height: 100%;
  display: flex;
}

.project-list-holder {
  height: 100%;
  width: 20%;
  padding: 70px 40px;
  box-sizing: border-box;
  position: sticky;
  top: 50px;
}

.project-list-holder li:first-child {
  font-size: 18px;
  font-weight: 500;
  cursor: default;
}

.project-list-holder li {
  margin-bottom: 50px;
  font-size: 12px;
  color: #000000;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  cursor: pointer;
}

.project-list-holder li:hover {
  color: #fff;
}

.project-display-holder {
  display: flex;
  flex-wrap: wrap;
  width: 80%;
  height: 100%;
  min-height: 100vh;
  margin-top: 15px;
}

.project-holder {
  height: 300px;
  width: 350px;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 10px;

  animation-duration: 4s;
}

.project-holder > img {
  width: 100%;
  height: 250px;
  object-fit: contain;
  cursor: pointer;
}

#item-selected {
  color: #000;
}

@media only screen and (max-width: 812px) {
  .project-full {
    display: block;
  }

  .project-list-holder {
    width: 100%;
    padding: 20px 0px;
    display: flex;
    justify-content: flex-start;
    align-content: center;
    background-color: #808080;
    position: sticky;
    top: 80 px;
    z-index: 3;
  }

  .project-list-holder > ul {
    list-style: none;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-around;
  }

  .project-list-holder li:first-child {
    display: none;
  }

  .project-list-holder li {
    margin-left: 5vw;
    margin-right: 5vw;
    color: #ffffff;
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 0px;
  }

  .project-holder {
    height: 350px;
    width: 350px;
    margin-left: 36px;
    margin-right: -8px;
    margin-top: 10px;
  }

  .project-display-holder {
    width: 100vw;
  }

  #item-selected {
    color: #333;
  }
}

@media only screen and (max-width: 600px) {
  .project-holder {
    height: 270px;
    width: 270px;
    margin-left: 16px;
    margin-right: 0px;
    margin-top: 10px;
  }

  .project-holder > img {
    height: 100%;
  }

  .project-list-holder li {
    margin-left: 5vw;
    margin-right: 5vw;
  }
}

@media only screen and (min-width: 320px) and (max-width: 568px) {
  .project-holder {
    margin-left: 0px;
    margin-right: 0px;
    width: auto;
    height: fit-content;
    display: flex;
    justify-content: center;
  }
  .project-display-holder {
    margin-top: 0px;
  }
  .project-holder > img {
    width: 70%;
  }
}

/* testing new projects layout */
