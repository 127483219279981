.about {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  animation-duration: 4s;
}

.left-about {
  height: inherit;
  display: flex;
  /* align-items: center; */
  position: fixed;
  top: 100px;
  bottom: 10px;
}

.right-about {
  height: inherit;
  width: 80%;
  margin-left: 18%;
}

.contact-about-holder {
  width: 100%;
  height: 200px;
  margin-top: 70%;
  padding: 75px 40px;
  box-sizing: border-box;
}

.contact-about-holder > p {
  margin-bottom: 10px;
  color: #303030;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
}

/* about content */

.about-main-words {
  width: 100%;
  height: 32%;
  display: flex;
  text-align: center;
  font-size: 52px;
  padding: 60px 40px;
  box-sizing: border-box;
  word-wrap: break-word;
  overflow: hidden;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  animation-duration: 2s;
}

.about-info-holder {
  width: 100%;
  height: 62%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.about-details {
  width: 30%;
  padding: 0px 40px;
  box-sizing: border-box;
  color: #303030;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  overflow-wrap: break-word;
  animation-duration: 3s;
}

.about-details > p:last-child {
  margin-top: 20px;
}

.about-info-holder > span {
  width: 40%;
  height: 600px;
  background: url(../../imgs/jorgecriollo.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: center;
  animation-duration: 1s;
  border-radius: 4px;
  box-shadow: 5px 10px #88888856;
}

.info-part2-holder {
  width: 100%;
  height: 600px;
  margin-top: 80px;
  display: flex;
  align-items: center;
}

.info-part2 {
  width: 500px;
  height: 500px;
  padding-top: 200px;
  box-sizing: border-box;
  margin-left: 50px;
}

.info-part2 > h2 {
  font-size: 36px;
  line-height: 46px;
  letter-spacing: 0.04em;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  color: #333;
}

.info-part2 > p {
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.02em;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  color: #333;
  margin-top: 10px;
}

.wood-img {
  background-image: url(../../imgs/tables/greenbenchclose.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  width: 500px;
  height: 500px;
  border-radius: 50%;
  margin-left: 20px;
}

@media only screen and (max-width: 812px) {
  .about {
    display: block;
  }

  .left-about {
    position: unset;
  }

  .contact-about-holder {
    margin-top: 0%;
    display: flex;
    justify-content: space-around;
    height: 100px;
    padding: 40px 40px;
  }

  .right-about {
    width: 100%;
    margin-left: 0%;
  }

  .about-main-words {
    width: 69%;
    height: 32%;
    text-align: left;
    display: flex;
    /* text-align: center; */
    font-size: 36px;
    padding: 50px 40px;
    box-sizing: border-box;
    word-wrap: break-word;
    overflow: hidden;
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    animation-duration: 2s;
  }

  .about-details {
    width: 45%;
    padding: 0px 0px;
  }

  .about-info-holder > span {
    width: 32%;
    height: 400px;
    box-shadow: none;
  }

  .info-part2-holder {
    margin-top: 0px;
    margin-bottom: 100px;
  }

  .info-part2 {
    width: 390px;
  }

  .wood-img {
    width: 300px;
    height: 300px;
  }
}

@media only screen and (max-width: 600px) {
  .info-part2 {
    width: 250px;
  }

  .wood-img {
    width: 250px;
    height: 250px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 811px) {
  .contact-about-holder {
    flex-direction: column;
    padding: 40px 15px;
  }

  .about-main-words {
    padding: 40px 40px;
    width: auto;
    background-color: rgb(208, 215, 195);
  }

  .about-info-holder {
    flex-direction: column-reverse;
  }

  .about-info-holder > span {
    width: 100%;
  }

  .about-details {
    width: fit-content;
    padding: 36px 45px;
  }

  .info-part2-holder {
    flex-direction: column;
    margin-bottom: 0px;
  }

  .info-part2 {
    width: 100%;
    padding: 50px 50px;
    height: auto;
    box-sizing: content-box;
    background-color: beige;
  }

  .wood-img {
    margin-top: 50px;
    margin-left: 0px;
  }
}
