.classic-full {
  width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.classic-main-img {
  width: 100%;
  height: 500px;
  position: relative;
}

.classic-main-img > img {
  width: 100%;
  height: 500px;
  object-fit: cover;
}

.classic-collect-title-holder {
  position: absolute;
  width: fit-content;
  height: fit-content;
  bottom: 20%;
  left: 3%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.classic-collect-h1 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.classic-collect-h1 > h1:first-child {
  font-size: 36px;
  letter-spacing: 3px;
  font-weight: 700;
  color: #fff;
  font-family: "Roboto", sans-serif;
  margin-right: 10px;
}

.classic-collect-h1 > h1 {
  font-size: 36px;
  letter-spacing: 3px;
  font-weight: 300;
  color: #fff;
  font-family: "Roboto", sans-serif;
}

.classic-collect-title-holder > p {
  margin-top: 12px;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 1px;
  color: #fff;
  font-family: "Roboto", sans-serif;
}

.classic-info-main-holder {
  width: 100vw;
  /* height: fit-content; */
  height: 1000px;
  /* background-color: beige; */
  display: flex;
  justify-content: center;
}

#classic-pic2-holder {
  top: 29%;
}

.classic-pic1 {
  width: 400px;
  height: 400px;
}

.classic-pic2 {
  width: 400px;
  height: 400px;
}

@media only screen and (min-width: 320px) and (max-width: 568px) {
  .classic-info-main-holder {
    height: fit-content;
  }

  .rhinestone-collect-h1 > h1:first-child {
    font-size: 20px;
    color: #333;
  }

  .rhinestone-collect-h1 > h1 {
    font-size: 20px;
    color: #333;
  }

  .rhinestone-collect-h1 {
    flex-direction: column;
  }

  .rhine-collect-title-holder > p {
    color: #333;
    font-size: 10px;
  }

  .rhine-collect-title-holder {
    right: 5%;
    left: auto;
    width: 200px;
    background: aliceblue;
    height: 200px;
  }

  /* rhinestone info mobile */

  .rhinestone-info-main-holder {
    flex-direction: column;
  }

  .rhinestone-center-info {
    width: 100vw;
  }

  .rhinestone-info-word-holder {
    width: 100vw;
    position: relative;
    left: 0;
    top: 0;
    padding: 30px 15px;
    box-sizing: border-box;
  }

  .rhinestone-info-word-holder > p {
    font-size: 14px;
  }

  .rhinestone-pic1-holder {
    position: relative;
    right: 0;
    top: 0;
    margin-bottom: 50px;
    padding: 0px 13px;
    box-sizing: border-box;
  }
  .rhinestone-pic1-holder > img {
    width: 388px;
    height: 510px;
  }

  .rhinestone-pic1-holder > p {
    margin-top: 20px;
    font-size: 12px;
  }

  .rhinestone-pic2-holder {
    position: relative;
    left: 0;
    top: 0;
    margin-bottom: 50px;
    padding: 0px 13px;
    box-sizing: border-box;
  }
  .rhinestone-pic2-holder > img {
    width: 388px;
    height: 510px;
  }

  .rhinestone-pic2-holder > p {
    margin-top: 20px;
    font-size: 12px;
  }

  .rhinestone-pic3-holder {
    position: relative;
    right: 0;
    top: 0;
    margin-bottom: 50px;
    padding: 0px 13px;
    box-sizing: border-box;
  }
  .rhinestone-pic3-holder > img {
    width: 388px;
    height: 510px;
  }

  .rhinestone-pic3-holder > p {
    margin-top: 20px;
    font-size: 12px;
  }

  .rhinestone-pic4-holder {
    position: relative;
    left: 0;
    top: 0;
    margin-bottom: 50px;
    padding: 0px 13px;
    box-sizing: border-box;
  }
  .rhinestone-pic4-holder > img {
    height: 400px;
    object-fit: cover;
    width: 100%;
  }

  .rhinestone-pic4-holder > p {
    margin-top: 20px;
    font-size: 12px;
  }

  .rhinestone-pic1-holder > img {
    width: 100%;
  }
  .rhinestone-pic2-holder > img {
    width: 100%;
  }
  .rhinestone-pic3-holder > img {
    width: 100%;
  }

  .rhinestone-pic1-holder > p::after {
    right: 15px;
  }
  .rhinestone-pic2-holder > p::after {
    right: 15px;
  }
  .rhinestone-pic3-holder > p::after {
    right: 15px;
  }
  .rhinestone-pic4-holder > p::after {
    right: 15px;
  }

  .extrapics {
    display: block;
  }
}
