.main-contact {
  height: 100vh;
  width: 100vw;
  background-image: url("../../imgs/chairs/bluestool.jpg");
  background-repeat: no-repeat;
  background-size: 100vw;
  object-fit: contain;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hello-holder {
  width: 70%;
  height: 60%;
  background-color: royalblue;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  backdrop-filter: saturate(180%) blur(10 px);
  background: rgba(0, 0, 0, 0.4);
  border-radius: 10 px;
  margin-bottom: 80px;
  border-radius: 20px;
}

.hello-holder > h1 {
  font-size: 130px;
  margin-bottom: 10px;
  color: #fff;
  cursor: pointer;
}

.hello-holder > h1:hover {
  color: rgb(206, 206, 155);
}

.hello-holder > p {
  line-height: 30px;
  font-size: 16px;
  color: #f2f2f2;
}

.hello-holder > i {
  margin-top: 15px;
  font-size: 25px;
  color: #fff;
  cursor: pointer;
}

@media only screen and (min-width: 320px) and (max-width: 568px) {
  .main-contact {
    height: auto;
    width: auto;
    background-size: cover;
    background-position-x: center;
  }
  .hello-holder > h1 {
    font-size: 45px;
  }
  .hello-holder {
    margin-bottom: 80px;
    margin-top: 80px;
    width: 100%;
    padding: 100px 30px;
    border-radius: 0px;
  }
  .hello-holder > i {
    margin-bottom: 15px;
  }
  .hello-holder > p {
    line-height: 50px;
    text-align: center;
  }
}
