.collection-full {
  width: 100vw;
  height: 900px;
  position: relative;
}

.collect1 {
  width: 500px;
  height: 300px;
  position: absolute;
  top: 50px;
  left: 250px;
  animation-duration: 3s;
}

.collect1 > div {
  background: url(../../imgs/couches/couch.jpeg);
  width: 100%;
  height: 90%;
  background-size: cover;
  background-position: center center;
}

.collect1 > div:hover,
.collect2 > div:hover,
.collect3 > div:hover {
  transform: scale(1.05);
  transition: all 0.6s cubic-bezier(0.61, 0.03, 0.32, 0.92) 0ms;
  border: 15px solid #fff;
  cursor: pointer;
}

.collect1 > p {
  margin-top: 20px;
  font-family: "Roboto", sans-serif;
  letter-spacing: 0.6px;
  font-weight: 700;
  font-size: 12px;
}

.collect1 > p::after {
  content: "|";
  position: absolute;
  left: 500px;
  font-weight: 1000;
}

.collect2 {
  width: 450px;
  height: 250px;
  position: absolute;
  top: 200px;
  left: 770px;
  animation-duration: 3s;
}

.collect2 > div {
  background: url(../../imgs/tables/greenbenchclose.jpg);
  width: 100%;
  height: 90%;
  background-size: cover;
  background-position-y: center;
}

.collect2 > p {
  margin-top: 10px;
  font-family: "Roboto", sans-serif;
  letter-spacing: 0.6px;
  font-weight: 700;
  font-size: 12px;
  color: #333;
}

.collect2 > p::after {
  content: "|";
  position: absolute;
  left: 450px;
  font-weight: 1000;
}

.collect3 {
  width: 500px;
  height: 300px;
  position: absolute;
  top: 470px;
  left: 400px;
  animation-duration: 3s;
}

.collect3 > div {
  background: url(../../imgs/chairs/pinkstool.jpg);
  width: 100%;
  height: 90%;
  background-size: cover;
  background-position-y: center;
}

.collect3 > p {
  margin-top: 10px;
  font-family: "Roboto", sans-serif;
  letter-spacing: 0.6px;
  font-weight: 700;
  font-size: 12px;
}

.collect3 > p::after {
  content: "|";
  position: absolute;
  left: 500px;
  font-weight: 1000;
}

@media only screen and (max-width: 600px) {
  .collection-full {
    height: 1050px;
  }

  .collect1 {
    left: 25px;
    width: 90%;
  }

  .collect2 {
    left: 25px;
    width: 90%;
    top: 400px;
  }

  .collect3 {
    top: 700px;
    padding-bottom: 30px;
    left: 25px;
    width: 90%;
  }

  .collect1 > p:after,
  .collect2 > p::after,
  .collect3 > p::after {
    left: 525px;
  }

  .collect1 > div:hover,
  .collect2 > div:hover,
  .collect3 > div:hover {
    transform: scale(0.9);
    transition: all 0.6s cubic-bezier(0.61, 0.03, 0.32, 0.92) 0ms;
    border: 10px solid #fff;
    cursor: pointer;
  }
}

@media only screen and (min-width: 700px) and (max-width: 812px) {
  .collection-full {
    height: 1050px;
  }

  .collect1 {
    left: 40px;
    width: 90%;
  }

  .collect2 {
    left: 40px;
    width: 90%;
    top: 400px;
  }

  .collect3 {
    top: 700px;
    padding-bottom: 30px;
    left: 40px;
    width: 90%;
  }

  .collect1 > p:after,
  .collect2 > p::after,
  .collect3 > p::after {
    left: 715px;
  }

  .collect1 > div:hover,
  .collect2 > div:hover,
  .collect3 > div:hover {
    transform: scale(0.9);
    transition: all 0.6s cubic-bezier(0.61, 0.03, 0.32, 0.92) 0ms;
    border: 10px solid #fff;
    cursor: pointer;
  }
}

@media only screen and (min-width: 320px) and (max-width: 568px) {
  .collect1,
  .collect2,
  .collect3 {
    left: 16px;
  }

  .collect1 > p:after,
  .collect2 > p::after,
  .collect3 > p::after {
    left: 370px;
  }
}
