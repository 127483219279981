.navbar {
  width: 100vw;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  position: sticky;
  bottom: 0;
  top: 0;
  color: #333;
  z-index: 1;

  animation-duration: 5s;
}

.left-nav {
  display: flex;
  align-items: center;
}

.left-nav > a {
  text-decoration: none;
}

.logo {
  /* margin-left: 50px;
  margin-top: 35px;
  height: 50px; */
  width: 150px;
  /* background-image: url(../../imgs/jmcdesignerlogo.png); */
  /* background-size: 100% 100%;
  background-repeat: no-repeat; */
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 24px;
  color: #333;
}

.nav-links {
  list-style: none;
  display: flex;
  justify-content: space-between;
  margin-left: 50px;
  margin-top: 10px;
}

.nav-links li {
  margin-right: 10px;
  margin-left: 10px;
  font-size: 12px;
  cursor: pointer;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #333;
}

.linkColor {
  color: #fff;
}

.nav-links a {
  text-decoration: none;
}

.right-nav {
  margin-right: 50px;
}

.contact-btn {
  cursor: pointer;
  background: none;
  border: 1px solid #ffffff;
  color: #ffffff;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 4px;
  margin-top: 10px;
  font-family: "Roboto", sans-serif;
  z-index: 2;
}

.fa-bars {
  display: none;
}

.fas fa-chevron-left {
  font-size: 20px;
}

.menu-phone {
  display: none;
}

@media only screen and (max-width: 600px) {
  .logo {
    width: 100px;
  }

  .nav-links {
    margin-left: -25px;
  }

  .contact-btn {
    border: 1px solid #333;
    color: #333;
  }
}

@media only screen and (max-width: 812px) {
  .contact-btn {
    border: 1px solid #333;
    color: #333;
  }
}

@media only screen and (min-width: 320px) and (max-width: 568px) {
  .nav-links {
    display: none;
  }

  .navbar {
    background-color: #333;
  }

  .contact-btn {
    display: none;
  }

  .fas fa-bars {
    display: block;
  }

  .fa-bars {
    display: block;
    color: white;
    padding-left: 120px;
    cursor: pointer;
  }

  .menu-phone {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: #f2f2f2;
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    z-index: 100;
  }

  .menu-phone > a {
    text-decoration: none;
  }

  .menu-phone li {
    list-style: none;
    color: #333;
    margin-bottom: 100px;
  }

  .menu-phone li:first-child {
    margin-top: 10px;
  }

  .fa-times {
    margin-left: 250px;
    margin-bottom: 50px;
    margin-top: 30px;
  }
}
